<template>
	<div class="container--880 mypage">
		<div class="mypage__header">
			<h1>회원정보</h1>
			<span>
				고객께서 가입하신 FCTS 회원 정보입니다. <br />정확한 확인을 위하여
				핸드폰 번호와 이메일 주소를 확인하세요.
			</span>
		</div>
		<div class="mypage__wrapper change-info">
			<div class="mypage__title">
				<h2>회원정보 수정</h2>
			</div>
			<div class="form__mypage">
				<!--아이디-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-1">아이디</label>
					</div>
					<div class="form__group" id="input-margin">
						<input
							id="input-1"
							v-model="myData.userID"
							placeholder="아이디(필수)"
							maxlength="12"
							type="text"
							readonly
						/>
					</div>
				</div>

				<!-- 전화번호 -->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-5"><em>*</em> 전화번호</label>
					</div>
					<div class="form__group" id="input-margin">
						<input
							id="input-5"
							v-model="myData.userPhone"
							:state="phoneState"
							placeholder="전화번호(필수)"
							maxlength="11"
							type="text"
							oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
						/>

						<!-- 유효성 검사 알림 -->
						<div v-show="this.phoneState == false" class="validateWarning">
							전화번호를 정확히 입력해주세요.
						</div>
						<div v-show="phoneState == true" class="validateSucccess">
							사용가능한 전화번호 입니다.
						</div>

						<!-- 설명 -->
						<div class="form-guide">-를 제외하고 입력해주세요.</div>
					</div>
				</div>

				<!--이메일-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-6"><em>*</em> 이메일</label>
					</div>
					<div class="form__group" id="input-margin">
						<input
							id="input-6"
							v-model="myData.userEmail"
							:state="emailState"
							placeholder="이메일(필수)"
							maxlength="60"
							type="text"
						/>

						<!-- 유효성 검사 알림 -->
						<div v-show="emailState == false" class="validateWarning">
							이메일 형식이 일치하지 않습니다.
						</div>
						<div v-show="emailState == true" class="validateSucccess">
							사용 가능한 이메일입니다.
						</div>

						<!-- 설명 -->
						<div class="form-guide">이메일 형식으로 입력해주세요.</div>
					</div>
				</div>

				<!--이름-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-4">이름</label>
					</div>
					<div class="form__group">
						<input
							id="input-4"
							v-model="myData.userName"
							type="text"
							required
							placeholder="이름"
							maxlength="20"
							oninput="this.value = this.value.replace(/^[0-9|\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\|]+$/, '').replace(/(\..*)\./g, '$1')"
						/>
					</div>
				</div>

				<!--생년월일-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-7">생년월일 </label>
					</div>
					<div class="form__group">
						<input
							id="input-7"
							v-model="myData.userBirth"
							type="date"
							required
							placeholder="생년월일"
						/>
					</div>
				</div>

				<!--회사네임-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-8"
							>기업 (선택)<span
								>*추천사가 있을 시 같이 입력해주세요.</span
							></label
						>
					</div>
					<div class="form__group">
						<input
							id="input-8"
							v-model="myData.userCompany"
							type="text"
							placeholder="회사 이름"
							maxlength="100"
						/>
					</div>
				</div>

				<!--주소-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="zipcode">주소</label>
					</div>
					<div class="form__group">
						<div class="input--addressbox">
							<input
								class="input--zipcode"
								id="zipcode"
								v-model="myData.zipCode"
								type="text"
								required
								disabled
								placeholder="우편번호"
							/>
							<button class="btn--zipcode" type="button" @click="addrSearch()">
								우편번호<br />
								검색
							</button>
						</div>
						<p>
							<input
								id="address"
								v-model="myData.address"
								type="text"
								required
								disabled
								placeholder="주소"
							/>
						</p>
						<p>
							<input
								id="addressExtra"
								v-model="myData.addressExtra"
								type="text"
								required
								disabled
								placeholder="참고사항"
							/>
						</p>
						<p>
							<input
								id="addressDetail"
								v-model="myData.addressDetail"
								type="text"
								placeholder="상세주소"
								maxlength="50"
							/>
						</p>
					</div>
				</div>
				<div class="btn__change">
					<button
						:class="{ btnDisabled: !isValid }"
						class="btn--sm"
						:disabled="!isValid"
						@click="submitForm"
					>
						회원정보 수정
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>
<script>
import { validateEmail } from '@/utils/validation';
import changeinfo from '@/services/changeinfo';

export default {
	data() {
		return {
			myData: {
				userID: '',
				userName: '',
				userPhone: '',
				userBirth: '',
				userEmail: '',
				userCompany: '',
				address: '',
				addressExtra: '',
				addressDetail: '',
				zipCode: '',
			},
		};
	},
	created() {
		// 회원정보 조회
		this.fetchMyInfo(this.$store.state.userid);
	},
	computed: {
		emailState() {
			if (this.myData.userEmail != '') {
				return validateEmail(this.myData.userEmail);
			} else {
				return null;
			}
		},
		phoneState() {
			if (this.myData.userPhone.length == '') {
				return null;
			} else {
				if (this.myData.userPhone.length >= 9) {
					return true;
				} else {
					return false;
				}
			}
		},
		isValid() {
			if (this.phoneState && this.emailState) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		// 회원정보 조회
		async fetchMyInfo(id) {
			changeinfo
				.fetchinfo(id)
				.then(res => {
					if (res.data) {
						this.myData = res.data;
						console.log('회원정보 조회', res.data);
					}
				})
				.catch(err => {
					console.log(err);
					alert('회원정보를 다시 확인해주세요.');
				});
		},
		// 회원정보 수정
		async submitForm() {
			const userData = {
				userID: this.myData.userID,
				userPhone: this.myData.userPhone,
				userEmail: this.myData.userEmail,
				userName: this.myData.userName,
				userBirth: this.myData.userBirth,
				userCompany: this.myData.userCompany,
				address: this.myData.address,
				addressExtra: this.myData.addressExtra,
				addressDetail: this.myData.addressDetail,
				addressZipCode: this.myData.zipCode,
			};
			console.log('test');
			changeinfo
				.changeinfo(userData)
				.then(res => {
					// 로그인 성공 시
					if (res.data.message == 'SUCCESS') {
						alert('회원정보 수정이 완료되었습니다.');
					}
				})
				.catch(err => {
					console.log(err);
					alert('회원정보를 다시 확인해주세요.');
				});
			// const { data } = await changeinfo.changeinfo(userData);
		},
		// 다음 주소 검색 api
		addrSearch() {
			new daum.Postcode({
				oncomplete: data => {
					let addr = ''; // 주소 변수
					let extraAddr = ''; // 참고항목 변수

					// 도로명 주소 선택한 경우(1)
					if (data.userSelectedType === 'R') {
						addr = data.roadAddress;

						// 지번 주소 선택한 경우
					} else {
						addr = data.jibunAddress;
					}
					// 도로명 주소 선택한 경우(2) -> 참고항목 조합
					if (data.userSelectedType === 'R') {
						// 법정동명 있을 경우(법정리는 제외 / 법정동은 마지막 문자가 동로가로 끝남)
						if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
							extraAddr += data.bname;
						} // 건물명이 있고 공동주택일 경우
						if (data.buildingName !== '' && data.apartment === 'Y') {
							extraAddr +=
								extraAddr !== '' ? ',' + data.buildingName : data.buildingName;
						}
						if (extraAddr !== '') {
							extraAddr = '(' + extraAddr + ')';
						}
						this.myData.addressExtra = extraAddr;
					} else {
						this.myData.addressExtra = '';
					}
					this.myData.zipCode = data.zonecode;
					this.myData.address = addr;
					// 커서를 상세주소 필드로 이동
					document.getElementById('addressDetail').focus();
				},
			}).open();
		},
	},
};
</script>
<style></style>
