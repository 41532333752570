import http from '../api/http-common';

class changeinfo {
	changeinfo(data) {
		return http.post('/changeinfo/change', data);
	}

	fetchinfo(data) {
		return http.get(`/changeinfo/fetchinfo/${data}`);
	}
}

export default new changeinfo();
